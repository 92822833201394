import React from "react";
import Navbar from "/src/containers/navbar";

import ForgotPass from "/src/containers/forgotpass";
import BacThemeProvider from "/src/providers/bac-theme-provider";

import config from "/src/config.js";
import SEO from "/src/components/seo";

const SigninPage = () => {
  return (
    <>
      <SEO
        title={`${config.CLIENT} - Signin`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <ForgotPass />
      </BacThemeProvider>
    </>
  );
};

export default SigninPage;
